<template>
  <div>
    <editben-Info :person="person" />
    <editben-Document :personDocument="person.documents" />
    <editben-Number :personNumber="person.contact_details" />
    <editben-Service :personServices="person.proposal_services" />
    <div>
      <b-modal
      ref="my-modal"
      centered
      cancel-title="No"
      cancel-variant="outline-secondary"
      title-tag="div"
    >
      <validation-observer ref="phonesimple">
        <b-form>
          <b-row>
    
                <b-col md="6" xl="6"      v-if="!updateWeaknessModel">
                  <validation-provider
             
                    #default="{ errors }"
                    name="weakname"
                    rules="required"
                  >
                    <b-form-group
                      label-for="weakname"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> نقاط الضعف</label>
                      <treeselect
                        :multiple="false"
                        :options="optionWeakness"
                        :reduce="(val) => val.id"
            
                        v-model="newWeakness.weakness_id"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col md="6" xl="6"  v-else>
                  <validation-provider
                 
                    #default="{ errors }"
                    name="weakname"
                    rules="required"
                  >
                    <b-form-group
                      label-for="weakname"
                      :state="errors.length > 0 ? false : null"
                    >
                      <label> نقاط الضعف</label>
                      <treeselect
                      disabled
                        :multiple="false"
                        :options="optionWeakness"
                        :reduce="(val) => val.id"
            
                        v-model="newWeakness.weakness_id"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col md="6" xl="6">
                  <b-form-group label-for="delimiter">
                    <label> الملاحظات</label>
                    <b-form-textarea
                      id="textarea-default"
                      placeholder="الملاحظات"
                      v-model="newWeakness.notes"
                    />
                  </b-form-group>
                </b-col>
                <!-- <b-col class="but" md="4" xl="2" v-if="weak.id">
                  <b-button variant="edit" @click="updateBenWek(weak.id)">
                    <span class="align-middle"> تعديل النقطة</span>
                  </b-button>
                </b-col> -->

              </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
       
              <b-button
              
              v-if="updateWeaknessModel"
                variant="edit"
                @click="updateBenWek(newWeakness.id)"
              >
                <span class="align-middle"> تعديل النقطة</span>
              </b-button>
        
        <b-button
        v-else
      
          variant="outline-primary"
          @click="addnewWeakness"
        >
          <span class="align-middle"> إضافة  </span>
        </b-button>
      </template>
    </b-modal>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-card-code title="معلومات نقاط الضعف">
          
             
         
           
           
            <b-table
            responsive="sm"
            :items="Personweaknesses"
            :fields="fields"
            show-empty
            empty-text="لا يوجد نقاط ضعف"
          >

            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
           
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item-button @click="showWeakness(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">تعديل</span>
                </b-dropdown-item-button>

              </b-dropdown>
            </template>
          </b-table>
          
          <b-row>
                <div>
                  <b-col class="border-Primary" md="2" xl="1">
                    <b-button variant="purple" @click="addWeak()">
                      <span class="align-middle"> إضافة نقطة اخر</span>
                    </b-button>
                  </b-col>
                </div>
              </b-row>
          </b-card-code>
        </b-form>
      </validation-observer>
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </div>
  </div>
</template>

<script>
// import FormTextAreaDefault from '..\form-textarea\FormTextArea.vue'

import editbenInfo from "./edit-ben-info.vue";
import editbenDocument from "./edit-ben-document.vue";
import editbenNumber from "./edit-ben-number.vue";
import editbenService from "./edit-ben-service.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Ripple from "vue-ripple-directive";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup } from "bootstrap-vue";

import {
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BForm,
  BButton,
  BImg,
  BFormInvalidFeedback,
  BTable,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BModal,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
// import '@core/scss/vue/libs/vue-flatpicker.scss';
import "flatpickr/dist/flatpickr.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "edit-beneficiary",

  components: {
    BTable,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BModal,
    editbenNumber,
    editbenService,
    editbenInfo,
    editbenDocument,
    BCardCode,

    BForm,
    Treeselect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    ToastificationContent,

    BImg,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    BFormTextarea,

    BRow,

    BCol,
  },

  data() {
    return {
      person: {},
      weak: [],
      updateWeaknessModel:false,
      optionWeakness: [],
      weaknote: "",
      Personweaknesses: [],
      newWeakness:{},
      fields: [
        { key: 'name', label: ' نقطة الضعف' },
    
        { key: 'notes', label: 'الملاحظة' },
        { key: 'actions', label: 'الخيارات' },
        
      ],
    };
  },

  directives: {
    Ripple,
  },

  created() {
    this.get();
    this.getWeak();
  },

  methods: {
    addnewWeakness() {
      return new Promise((resolve, reject) => {
        this.$refs.phonesimple.validate().then(success => {
          if (success) {
            // this.personServices.push(this.newService)
            this.addPersonWek()
            this.$refs['my-modal'].hide()
        
          }
        })
      })
    },
    addPersonWek(i) {
      let data = null;

      data = {
    person_id: this.$route.params.id,
    weakness_id: this.newWeakness.weakness_id,
    notes: this.newWeakness.notes,
  };
console.log(data)

this.$http
  .post("/api/v1/pending_actions/person_weaknesses", data)
  .then((response) => {
    this.newWeakness.notes=''
    this.newWeakness.weakness_id=''
    this.$swal({
      title: "",
      text: `  تم إضافة النقطة   بنجاح بانتظار موافقة مدير المعلومات   `,
      icon: "success",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
    });

    requestAnimationFrame(() => {
      this.$refs.simpleRules.reset();
    });
  })
  .catch((error) => {
    this.$swal({
      title: `${error.response.data.message}`,
      icon: "error",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
    });
  });
    },
    updateBenWek(id) {
      let data;
    
        data = {
          notes: this.newWeakness.notes,
        };
  

      // //console.log(this.weak);
      this.$http
        .put("/api/v1/pending_actions/person_weaknesses/" + id, data)
        .then((res) => {
          this.updateWeaknessModel=false
          //console.log(res);
          this.$swal({
            title: "تم طلب التعديل بانتظار موافقة مدير المعلومات",

            icon: "success",
            confirmButtonText: "موافق",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    showWeakness(item){
      this.$refs['my-modal'].show()
      this.updateWeaknessModel=true
      this.newWeakness=item
      this.newWeakness.weakness_id=item.id
    },
    getWeak() {
      this.optionsWeak = [];
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
        let subweak = res.data.data.sub_weaknesses;
        let Weak = res.data.data.weaknesses;

        Weak.forEach((el) => {
          let obj = {};
          obj.children = [];
          obj.label = el.name;
          obj.id = el.id;

          subweak.forEach((ele, index) => {
            if (el.id == ele.parent_id) {
              obj.children.push({ label: subweak[index].name, id: ele.id });
            }
          });
          this.optionWeakness.push(obj);
        });
      });
    },
    deleteWeak(index) {
      this.Personweaknesses.splice(index, 1);
    },
    addWeak() {
      this.updateWeaknessModel=false
      this.newWeakness.notes=''
    this.newWeakness.weakness_id=''
      this.$refs['my-modal'].show()
      // this.Personweaknesses.push({
      //   id: "",
      //   notes: "",
      // });
    },
    get() {
      let url = `/api/v1/persons/${this.$route.params.id}`;

      this.$http.get(url).then((res) => {
        //console.log(res.data.data);
        this.person = res.data.data;
        this.Personweaknesses = res.data.data.weaknesses;
        // this.person.weaknesses.forEach((el) => {
        //   this.weaknote = el.weaknote;
        // });
        //     //console.log( this.person.weaknesses);
        // this.person.weaknesses.forEach((ele) => {
        //   this.weak.push(ele.id);
        // });
      });
    },
  },
};
</script>

<style scoped>
label {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #0971b8 !important;
}
.align-left {
  left: 50px;
}
.btn-edit {
  color: white;
  width: 200px;
  background-color: #28c76f !important;
}
.but {
  top: 40px;
}
</style>
